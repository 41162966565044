import React, { useEffect, useState, useMemo, useContext } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HistoryIcon from '@mui/icons-material/History';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const FooterMenu: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {user} = useContext(UserContext);

    const menuItems = useMemo(() => [
        {label: 'Home', icon: <HomeRoundedIcon/>, outlinedIcon: <HomeOutlinedIcon/>, path: user?.role === 'Merchant' ? '/merchant/home' : '/home'},
        {label: 'Transactions', icon: <HistoryIcon/>, outlinedIcon: <HistoryOutlinedIcon/>, path: user?.role === 'Merchant' ? '/merchant/transactions' : '/transactions'},
        {label: 'Settings', icon: <SettingsIcon/>, outlinedIcon: <SettingsOutlinedIcon/>, path: user?.role === 'Merchant' ? '/merchant/settings' : '/settings'},
    ], [user?.role]);

    const initialValue = useMemo(() => {
        return  menuItems.findIndex(item => item.path === location.pathname);
    }, [location.pathname, menuItems]);

    const [value, setValue] = useState(initialValue);

    const navButtonStyles = {
        color: 'rgba(57, 57, 57, 0.4)',
        '&.Mui-selected': {
            color: 'black',
            fontWeight: 600,
            fontSize: '12px'
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: '12px',
        },
    };

    useEffect(() => {
        const currentIndex = menuItems.findIndex(item => item.path === location.pathname);
        setValue(currentIndex);
    }, [location.pathname, menuItems]);

    return (
        <Paper elevation={3}>
            <BottomNavigation
                sx={{margin: '0 30px', height: '90px'}}
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    navigate(menuItems[newValue].path);
                }}
                showLabels
            >
                {menuItems.map((item, index) => (
                    <BottomNavigationAction
                        key={item.path}
                        label={item.label}
                        icon={value === index ? item.icon : item.outlinedIcon}
                        sx={navButtonStyles}
                    />
                ))}
            </BottomNavigation>
        </Paper>
    );
};

export default FooterMenu;