export const validateEmail = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const validatePassword = (password: string): boolean => {
    return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W_]{8,}$/.test(password);
};

export const validateUsername = (username: string): boolean => {
    return /^(?!.* {2})[A-Za-z0-9_ -]{3,50}$/.test(username.trim());
};

export const validatePhoneNumber = (phoneNumber: string): boolean => {
    const cleanedPhoneNumber = phoneNumber.replace(/[^\d+]/g, '');
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(cleanedPhoneNumber);
};

export const getDateTimeText = (date: Date): string => {
    return new Date(date).toLocaleString(navigator.language, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    })
}

export const formatPhoneNumber = (input: string): string => {
    const cleanedNumber = input.replace(/\D/g, '');
    return `+1${cleanedNumber}`;
};