import React, { useState } from 'react';
import {
    Container,
    Box,
    Typography,
    Button,
    TextField,
    FormControl, InputAdornment,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { validateEmail } from '../../../services/utils';
import { useForm } from '../../../hooks/useForm';
import { sendPasswordResetEmailHandler } from '../../../services/api';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordPage: React.FC = () => {
    const navigate = useNavigate();
    const initialFormValues = {email: ''};

    const validate = (values: typeof initialFormValues) => ({
        email: validateEmail(values.email),
    });

    const {
        formData,
        formTouched,
        isFormValid,
        handleInputChange,
        handleBlur,
    } = useForm({initialValues: initialFormValues, validate});

    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    const handlePasswordReset = async () => {
        if (isFormValid) {
            try {
                await sendPasswordResetEmailHandler(formData.email);
                setSuccess('If an account exists with this email address, you’ll receive an email with password reset link.');
            } catch (err) {
                setError('Failed to send password reset email. Please try again.');
            }
        }
    };

    return (
        <Container maxWidth="xs" sx={{px: 3}}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                minHeight="100dvh"
                alignItems="center"
            >

                <Box component="form">
                    <Typography variant="h1" align="left">
                        Forgot password?
                    </Typography>

                    <FormControl fullWidth variant="outlined" sx={{marginBottom: '20px'}}>
                        <Typography variant="body1" fontWeight={600}>
                            Email
                        </Typography>
                        <TextField
                            id="email"
                            type="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={!validateEmail(formData.email) && formTouched.email}
                            helperText={!validateEmail(formData.email) && formTouched.email ? 'Please enter a valid email address' : ''}
                            InputProps={{
                                endAdornment: validateEmail(formData.email) && formTouched.email ? (
                                    <InputAdornment position="end">
                                        <Check color="success"/>
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                    </FormControl>

                    {error && <Typography mb={4} color="error">{error}</Typography>}
                    {success && <Typography mb={4} color="success">{success}</Typography>}

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => success ? navigate('/merchant/login') : handlePasswordReset()}
                        disabled={!isFormValid}
                    >
                        Continue
                    </Button>
                </Box>
                <div></div>
            </Box>
        </Container>
    );
};

export default ForgotPasswordPage;