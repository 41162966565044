import React, { useContext, useEffect } from 'react';
import { AppBar, Avatar, Badge, Box, IconButton, Stack, Toolbar, Tooltip, Typography, } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { UserContext } from '../context/UserContext';
import useSnackbar from '../hooks/useSnackbar';
import api from '../services/api';
import { NotificationsContext } from '../context/NotificationContext';
import BackButton from './BackButton';
import { Role } from './PrivateRoute';
import { StarRounded } from '@mui/icons-material';

interface HeaderProps {
    title?: string;
    showBack?: boolean;
    showNotifications?: boolean;
    avatarUrl?: string;
    username?: string;
    statistics?: {
        totalTossed: number;
        totalReceived: number;
    }
}

const MerchantHeader: React.FC<HeaderProps> = ({
                                                   title,
                                                   showBack = true,
                                                   showNotifications = true,
                                                   avatarUrl,
                                                   username,
                                                   statistics
                                               }) => {
    const navigate = useNavigate();
    const {user} = useContext(UserContext);
    const {notificationCount, setNotificationCount} = useContext(NotificationsContext);
    const {showSnackbar} = useSnackbar();

    useEffect(() => {
        const fetchNotificationCount = async () => {
            if (!user?.id || !showNotifications) {
                return;
            }

            try {
                const query = qs.stringify(
                    {
                        filters: {
                            user: {
                                id: {
                                    $eq: user.id,
                                },
                            },
                        },
                        pagination: {
                            pageSize: 0,
                        },
                    },
                    {
                        encodeValuesOnly: true,
                    }
                );

                const response = await api.get(`/notifications?${query}`);

                const total = response.data.meta.pagination.total;
                setNotificationCount(total);
            } catch (err) {
                console.error('Error fetching notification count:', err);
                showSnackbar('Error fetching notification count.', 'error');
            }
        };

        fetchNotificationCount();
    }, [user?.id, setNotificationCount, showSnackbar, showNotifications]);

    const ratio = statistics ? (statistics.totalTossed / (statistics.totalReceived + statistics.totalTossed)) : 0

    return (
        <AppBar position="static" color="inherit" elevation={0} sx={{py: 2}}>
            <Toolbar sx={{justifyContent: 'space-between'}}>
                {showBack && (
                    <BackButton/>
                )}
                {statistics ?
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {avatarUrl && (
                            <Avatar alt="User Avatar" src={avatarUrl} sx={{width: 48, height: 48, mr: 3}}/>
                        )}
                        <Tooltip enterTouchDelay={0}
                                 title={
                                     <>
                                         <Typography color="inherit">Tossed: {statistics.totalTossed}</Typography>
                                         <Typography color="inherit">Received: {statistics.totalReceived}</Typography>
                                     </>
                                 }>

                            <Stack>
                                <Stack>
                                    <Typography
                                        fontSize={15}
                                        fontWeight={500}
                                        marginBottom={0}
                                        color="rgba(160, 157, 180, 1)"
                                    >
                                        Welcome,
                                    </Typography>
                                    <Typography
                                        fontSize={15}
                                        fontWeight={500}
                                        marginBottom={0}
                                        color="black"
                                    >
                                        {username} 👋🏼
                                    </Typography>
                                </Stack>

                                <Stack>
                                    <StarRounded sx={{
                                        fontSize: '14px',
                                        color: 'rgba(255, 166, 41, 1)',
                                        position: 'relative',
                                        left: `${ratio * 100}%`,
                                        transform: 'translate(-50%, 0)',
                                        mb: '2px'
                                    }}/>
                                    <Box bgcolor={'rgba(144, 144, 169, 1)'} height={2} width={120} borderRadius={1 / 2}
                                         display="flex" alignItems={'center'}>
                                        <Box height={0} border={'0.5px dashed black'} marginX={'2px'}
                                             width={'100%'}></Box>
                                    </Box>
                                    <Box sx={{transform: 'translate(-50%, 0)'}} height={6} width={6}
                                         borderRadius={'50%'}
                                         bgcolor={'rgba(255, 166, 41, 1)'}
                                         position={'relative'} top={'-4px'} left={`${ratio * 100}%`}></Box>
                                    <Box display={'flex'} justifyContent={'space-between'} mt={'-4px'}>
                                        <Typography fontSize={10} color={'black'} mb={0}>Receiver</Typography>
                                        <Typography fontSize={10} color={'black'} mb={0}>Tosser</Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Tooltip>
                    </Box>
                    : <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {avatarUrl && (
                            <Avatar alt="User Avatar" src={avatarUrl} sx={{width: 48, height: 48, mr: 3}}/>
                        )}
                        {username ? (
                            <Typography
                                fontSize={15}
                                fontWeight={500}
                                marginBottom={0}
                                color="rgba(160, 157, 180, 1)"
                            >
                                {username}
                            </Typography>
                        ) : (
                            title && (
                                <Typography fontSize={20} fontWeight="bold" marginBottom={0}>
                                    {title}
                                </Typography>
                            )
                        )}
                    </Box>}
                {showNotifications ? (
                    <IconButton
                        color="inherit"
                        sx={{border: '0.5px solid rgba(21, 21, 33, 0.2)'}}
                        onClick={() => navigate(user?.role === Role.Merchant ? '/merchant/notifications' : '/notifications')}
                    >
                        <Badge
                            badgeContent={
                                notificationCount
                            }
                            color="warning"
                        >
                            <NotificationsIcon sx={{color: 'rgba(57, 57, 67, 1)'}}/>
                        </Badge>
                    </IconButton>
                ) : (
                    <Box sx={{width: '86px'}}></Box>
                )}
            </Toolbar>
        </AppBar>
    )
        ;
};

export default React.memo(MerchantHeader);