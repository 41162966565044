export const calculateTimeLeft = (expirationDate: Date) => {
    const now = new Date();
    const diffInMs = expirationDate.getTime() - now.getTime();

    if (diffInMs <= 0) return 'Expired';

    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

    return `${days}d ${hours}h ${minutes}min`;
};