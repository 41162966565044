import React, { FC } from 'react';
import {
    Button,
    ClickAwayListener,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import { Token } from '../tokens/TokenDetailsPage';

const FilterDropdown: FC<{
    tokens: Token[];
    selectedToken: string;
    onChange: (value: string) => void;
    onApply: () => void;
    open: boolean;
    onClickAway: (event: MouseEvent | TouchEvent) => void;
}> = ({tokens, selectedToken, onChange, onApply, open, onClickAway}) => (
    open ? (
        <ClickAwayListener onClickAway={onClickAway}>
            <Paper
                elevation={3}
                sx={{
                    position: 'absolute',
                    top: '195px',
                    right: '16px',
                    zIndex: 10,
                    bgcolor: 'white',
                    borderRadius: '12px',
                    maxHeight: 'calc(100% - 330px)',
                    height: tokens.length > 10 ? '100%' : 'auto',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    p: 2,
                    width: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <FormControl sx={{overflow: 'auto'}} component="fieldset">
                    <RadioGroup
                        aria-label="token"
                        value={selectedToken}
                        onChange={(e) => onChange(e.target.value)}
                    >
                        <FormControlLabel
                            value="all"
                            control={
                                <Radio
                                    sx={{
                                        color: 'rgba(57, 57, 67, 1)',
                                        '&.Mui-checked': {
                                            color: 'rgba(57, 57, 67, 1)',
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography
                                    sx={{
                                        color: 'rgba(132, 132, 132, 1)',
                                        mb: 0,
                                    }}
                                >
                                    Show all
                                </Typography>
                            }
                        />
                        {tokens.map((token) => (
                            <FormControlLabel
                                key={token.id}
                                value={token.id}
                                control={
                                    <Radio
                                        sx={{
                                            color: 'rgba(57, 57, 67, 1)',
                                            '&.Mui-checked': {
                                                color: 'rgba(57, 57, 67, 1)',
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{
                                            color: 'rgba(132, 132, 132, 1)',
                                            mb: 0,
                                        }}
                                    >
                                        {token.itemName}
                                    </Typography>
                                }
                            />
                        ))}
                    </RadioGroup>
                </FormControl>

                <Button
                    sx={{
                        width: 'fit-content',
                        alignSelf: 'end',
                        backgroundColor: 'transparent',
                        color: 'rgba(57, 57, 67, 1)',
                        '&:hover': {
                            backgroundColor: 'rgba(245, 245, 245, 1)',
                        }
                    }}
                    onClick={onApply}
                >
                    Apply
                </Button>
            </Paper>
        </ClickAwayListener>
    ) : null
);

export default FilterDropdown;