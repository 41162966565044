import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

const NavigationButtons: FC<{ onNavigate: (path: string) => void }> = ({onNavigate}) => (
    <Box sx={{textAlign: 'center', mt: 4, mb: 1, display: 'flex', gap: '10px'}}>
        <Button
            variant="contained"
            startIcon={<TokenOutlinedIcon/>}
            onClick={() => onNavigate('/merchant/tokens')}
            sx={{
                height: 50,
                flex: 1,
                borderRadius: '12px',
                backgroundColor: 'rgba(245, 245, 245, 1)',
                color: 'rgba(57, 57, 67, 1)',
                '&:hover': {
                    backgroundColor: 'rgba(230, 230, 230, 1)',
                },
            }}
        >
            Tokens
        </Button>
        <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineRoundedIcon/>}
            onClick={() => onNavigate('/merchant/issue-tokens')}
            sx={{height: 50, flex: 1, borderRadius: '12px'}}
        >
            Issue Token
        </Button>
    </Box>
);

export default NavigationButtons;