import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    FormControl,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography
} from '@mui/material';
import { Check, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { validateEmail, validatePassword } from '../../../services/utils';
import { useForm } from '../../../hooks/useForm';
import { authenticateWithFirebase, jwtTokenKey } from '../../../services/api';
import { UserContext } from '../../../context/UserContext';
import { Role } from '../../../components/PrivateRoute';
import { auth } from '../../../services/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';

const MerchantLoginPage: React.FC = () => {
    const initialFormValues = {email: '', password: ''};

    const validate = (values: typeof initialFormValues) => ({
        email: validateEmail(values.email),
        password: validatePassword(values.password),
    });

    const {
        formData,
        formTouched,
        isFormValid,
        handleInputChange,
        handleBlur,
    } = useForm({initialValues: initialFormValues, validate});

    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const {fetchUser, user} = useContext(UserContext);

    useEffect(() => {
        if (localStorage.getItem(jwtTokenKey)) {
            if (user?.role === Role.User) {
                navigate('/home');
            } else {
                navigate('/merchant/home');
            }
        }
    }, [navigate, user?.role]);

    const handleLogin = async () => {
        if (isFormValid) {
            try {
                const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.password);
                const firebaseUser = userCredential.user;

                const idToken = await firebaseUser.getIdToken();

                await authenticateWithFirebase(idToken, {
                    email: formData.email
                });

                await fetchUser();
                navigate('/merchant/home');
            } catch (err: any) {
                console.error('Login error:', err);
                setError('Login failed. Please check your credentials and try again.');
            }
        }
    };

    return (
        <Container maxWidth="xs" sx={{px: 3}}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-around"
                minHeight="100dvh"
            >
                <Box component="form">
                    <Typography variant="h1" align="left">
                        Merchant Sign in
                    </Typography>

                    <FormControl fullWidth variant="outlined" sx={{marginBottom: '20px'}}>
                        <Typography variant="body1" fontWeight={600}>
                            Email
                        </Typography>
                        <TextField
                            id="email"
                            type="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={!validateEmail(formData.email) && formTouched.email}
                            helperText={!validateEmail(formData.email) && formTouched.email ? 'Please enter a valid email address' : ''}
                            InputProps={{
                                endAdornment: validateEmail(formData.email) && formTouched.email ? (
                                    <InputAdornment position="end">
                                        <Check color="success"/>
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{marginBottom: '20px'}}>
                        <Typography variant="body1" fontWeight={600}>
                            Password
                        </Typography>
                        <TextField
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            value={formData.password}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={!validatePassword(formData.password) && formTouched.password}
                            helperText={!validatePassword(formData.password) && formTouched.password ? 'Password must be at least 8 characters long and include at least one letter and one number.' : ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                            color="info"
                                            sx={{marginRight: '-10px'}}
                                        >
                                            {showPassword ? <VisibilityOffOutlined/> : <VisibilityOutlined/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    <Box display="flex" justifyContent="flex-end" mt={-1} mb={2}>
                        <Link href="/merchant/forgot-password" variant="body2">
                            Forgot password?
                        </Link>
                    </Box>

                    {error && <Typography color="error">{error}</Typography>}

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleLogin}
                        disabled={!isFormValid}
                    >
                        Sign in
                    </Button>

                    <Box sx={{marginTop: '30px', marginBottom: '16px'}}>
                        <Typography variant="body1" align="center">
                            New customer?{' '}
                            <Link href="/merchant/signup" variant="body2">
                                Create new merchant account
                            </Link>
                        </Typography>
                    </Box>
                </Box>

                <div></div>
            </Box>
        </Container>
    );
};

export default MerchantLoginPage;