import React from 'react';
import { Box, Typography } from '@mui/material';

const Loading: React.FC = () => {
    return (
        <Box height='100dvh' width='1' sx={{backgroundColor: 'rgba(57, 57, 67, 1)'}}>
            <Typography fontSize={36} fontWeight={700} color='white' textAlign='center' pt='33dvh'>Toss Tech</Typography>
        </Box>
    );
};

export default Loading;