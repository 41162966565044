export const datePickerStyles = {
    actionBar: {
        sx: {
            '& .MuiButton-root': {
                color: 'rgba(57, 57, 67, 1)',
                backgroundColor: 'white',
            },
        },
    },
    day: {
        sx: {
            '&.Mui-selected': {
                backgroundColor: 'rgba(255, 166, 41, 1)',
                color: 'white',
                '&:focus, &:hover': {
                    backgroundColor: 'rgba(255, 166, 41, 1)',
                },
            },
            '&:hover': {
                backgroundColor: 'rgba(255, 166, 41, 0.5)',
            },
        },
    },
    desktopPaper: {
        sx: {
            '.MuiPickersYear-yearButton.Mui-selected':{
                backgroundColor: 'rgba(255, 166, 41, 1)',
                '&:hover': {
                    backgroundColor: 'rgba(255, 166, 41, 0.5)',
                },
            }
        }
    },
    mobilePaper: {
        sx: {
            '.MuiPickersYear-yearButton.Mui-selected':{
                backgroundColor: 'rgba(255, 166, 41, 1)',
                '&:focus': {
                    backgroundColor: 'rgba(255, 166, 41, 1)',
                },
            }
        }
    }
}