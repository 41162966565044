import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBI1izj8foFieHnR62Z534sJ0DOG5svK3E",
    authDomain: "tosstech-28234.firebaseapp.com",
    projectId: "tosstech-28234",
    storageBucket: "tosstech-28234.appspot.com",
    messagingSenderId: "1070900407438",
    appId: "1:1070900407438:web:b15196ed9c46b0bf386ddf"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);