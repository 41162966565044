import React from 'react';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const BackButton: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Button
            onClick={() => navigate(-1)}
            color="primary"
            startIcon={<ArrowBackIcon fontSize="small"/>}
            sx={{
                borderRadius: '12px',
                color: 'white',
                height: '28px',
                fontSize: '14px'
            }}
        >
            Back
        </Button>
    );
};

export default BackButton;