import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface ChartProps {
    value: number;
    color: string;
    icon: OverridableComponent<SvgIconTypeMap>;
}

const Chart: React.FC<ChartProps> = ({ value, color, icon: Icon }) => {
    return (
        <Box position="relative" width={50} height={50}>
            <CircularProgress
                variant="determinate"
                value={100}
                size={50}
                thickness={3.5}
                sx={{
                    color: 'rgba(245, 245, 245, 1)',
                }}
            />
            <CircularProgress
                variant="determinate"
                value={value}
                size={50}
                thickness={3.5}
                sx={{
                    color: color,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    strokeLinecap: 'round'
                }}
            />
            <Box
                position="absolute"
                top="50%"
                left="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Box height='24px' width='24px' sx={{ backgroundColor: "rgba(245, 245, 245, 1)" }} borderRadius='50%'
                     display='flex' justifyContent='center' alignItems='center'>
                    <Icon sx={{ fontSize: '15px', color: color, mb: '1px' }} />
                </Box>
            </Box>
        </Box>
    );
}

export default Chart;