import React, { useState } from 'react';
import {
    Container,
    Box,
    Typography,
    Button,
    IconButton,
    InputAdornment,
    FormControl,
    TextField
} from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validatePassword } from '../../../services/utils';
import { useForm } from '../../../hooks/useForm';
import { resetPassword } from '../../../services/api';

const ResetPasswordPage: React.FC = () => {
    const initialFormValues = {password: '', confirmPassword: ''};

    const validate = (values: typeof initialFormValues) => ({
        password: validatePassword(values.password),
        confirmPassword: values.password === values.confirmPassword && values.confirmPassword !== '',
    });

    const {
        formData,
        formTouched,
        isFormValid,
        handleInputChange,
        handleBlur,
    } = useForm({initialValues: initialFormValues, validate});

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");

    const handleResetPassword = async () => {
        if (isFormValid) {
            try {
                await resetPassword(formData.password, formData.confirmPassword, code);
                setSuccess('Password reset successfully');
                navigate('/merchant/login');
            } catch (err) {
                setError('Failed to reset password. Please try again.');
            }
        }
    };

    return (
        <Container maxWidth="xs" sx={{px: 3}}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-around"
                minHeight="100dvh"
            >
                <Box component="form">
                    <Typography variant="h1" align="left">
                        Set new password
                    </Typography>

                    <FormControl fullWidth variant="outlined" sx={{marginBottom: '20px'}}>
                        <Typography variant="body1" fontWeight={600}>
                            New Password
                        </Typography>
                        <TextField
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            value={formData.password}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={!validatePassword(formData.password) && formTouched.password}
                            helperText={!validatePassword(formData.password) && formTouched.password ? 'Password must be at least 8 characters long and include at least one letter and one number.' : ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                            color="info"
                                            sx={{marginRight: '-10px'}}
                                        >
                                            {showPassword ? <VisibilityOffOutlined/> : <VisibilityOutlined/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{marginBottom: '20px'}}>
                        <Typography variant="body1" fontWeight={600}>
                            Confirm Password
                        </Typography>
                        <TextField
                            id="confirmPassword"
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={formTouched.confirmPassword && formData.password !== formData.confirmPassword}
                            helperText={formTouched.confirmPassword && formData.password !== formData.confirmPassword ? 'Passwords do not match.' : ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            edge="end"
                                            color="info"
                                            sx={{marginRight: '-10px'}}
                                        >
                                            {showConfirmPassword ? <VisibilityOffOutlined/> : <VisibilityOutlined/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    {error && <Typography color="error">{error}</Typography>}
                    {success && <Typography color="success">{success}</Typography>}

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleResetPassword}
                        disabled={!isFormValid}
                    >
                        Update password
                    </Button>
                </Box>
                <div></div>
            </Box>
        </Container>
    );
};

export default ResetPasswordPage;