import React from 'react';
import { Button, Checkbox, ClickAwayListener, FormControlLabel, Paper, Typography, } from '@mui/material';

type FilterState = {
    Declined: boolean;
    Tossed: boolean;
    Spinning: boolean;
    showAll: boolean;
};

type FilterKey = keyof FilterState;

interface FilterMenuProps {
    filter: FilterState;
    onFilterChange: (field: FilterKey, value?: boolean) => void;
    onApplyFilter: () => void;
    filterOpen: boolean;
    setFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
    filterButtonRef: React.RefObject<HTMLButtonElement>;
}

const FilterMenu: React.FC<FilterMenuProps> = ({
                                                   filter,
                                                   onFilterChange,
                                                   onApplyFilter,
                                                   filterOpen,
                                                   setFilterOpen,
                                                   filterButtonRef,
                                               }) => {
    const handleClickAway = (event: MouseEvent | TouchEvent) => {
        if (filterButtonRef.current && filterButtonRef.current.contains(event.target as Node)) {
            return;
        }
        setFilterOpen(false);
    };

    return (
        <>
            {filterOpen && (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Paper
                        elevation={3}
                        sx={{
                            position: 'fixed',
                            mt: '16px',
                            bgcolor: 'white',
                            borderRadius: '12px',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            p: 2,
                            width: '200px',
                            display: 'flex',
                            flexDirection: 'column',
                            transform: 'translateX(-72%)',
                            zIndex: 1000,
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filter.showAll}
                                    onChange={() => {
                                        onFilterChange('Declined', !filter.showAll);
                                        onFilterChange('Tossed', !filter.showAll);
                                        onFilterChange('Spinning', !filter.showAll);
                                    }}
                                />
                            }
                            label={<Typography sx={{ color: 'rgba(132, 132, 132, 1)', marginBottom: 0 }}>Show All</Typography>}
                            disableTypography
                        />
                        {(['Spinning', 'Tossed', 'Declined'] as FilterKey[]).map((key) => (
                            <FormControlLabel
                                key={key}
                                control={<Checkbox checked={filter[key]} onChange={() => onFilterChange(key)} />}
                                label={<Typography sx={{ color: 'rgba(132, 132, 132, 1)', marginBottom: 0 }}>{key}</Typography>}
                                disableTypography
                            />
                        ))}
                        <Button
                            sx={{
                                width: 'fit-content',
                                alignSelf: 'end',
                                backgroundColor: 'transparent',
                                color: 'rgba(57, 57, 67, 1)',
                                '&:hover': {
                                    backgroundColor: 'rgba(245, 245, 245, 1)',
                                },
                            }}
                            onClick={onApplyFilter}
                        >
                            Apply
                        </Button>
                    </Paper>
                </ClickAwayListener>
            )}
        </>
    );
};

export default FilterMenu;