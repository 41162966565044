import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import Loading from './Loading';

export enum Role {
    Merchant = 'Merchant',
    User = 'User'
}

interface PrivateRouteProps {
    element: React.ReactElement;
    role: Role;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({element, role}) => {
    const {user, loading} = useContext(UserContext);

    if (loading) {
        return <Loading/>;
    }

    if (!user) {
        return role === Role.Merchant ? <Navigate to="/merchant/login"/> : <Navigate to="/login"/>;
    }

    if (user.role !== role) {
        return <Navigate to="/"/>;
    }

    return element;
};

export default PrivateRoute;