import { useContext, useState } from 'react';
import api from '../services/api';
import { Token } from '../pages/merchant/tokens/TokenDetailsPage';
import { UserContext } from '../context/UserContext';

type ShowSnackbarFunction = (message: string, severity?: 'success' | 'error') => void;

export const useUpdateTokenStatus = (showSnackbar: ShowSnackbarFunction) => {
    const [updatingTokenId, setUpdatingTokenId] = useState<number | null>(null);
    const {user} = useContext(UserContext);

    const updateTokenStatus = async (
        token: Token,
        newStatus: boolean,
        updateLocalToken: (tokenId: number, newStatus: boolean) => void
    ) => {
        if (!user) {
            return;
        }

        try {
            setUpdatingTokenId(token.id);
            if (newStatus) {
                const res = await api.get('/tokens', {
                    params: {
                        filters: {
                            itemName: {$eq: token.itemName},
                            $and: [
                                {isActive: true},
                                {expirationDate: {$gte: new Date().toISOString()}},
                            ], merchant: {id: {$eq: user.id}},
                        },
                    },
                });

                if (res.data?.data?.length) {
                    showSnackbar(
                        'A token with this name already exists. Please deactivate the existing token',
                        'error'
                    );
                    setUpdatingTokenId(null);
                    return;
                }
            }

            updateLocalToken(token.id, newStatus);
            await api.put(`/tokens/${token.id}`, {data: {isActive: newStatus}});
            showSnackbar('Token status updated successfully', 'success');
        } catch (error) {
            showSnackbar('Error updating token status', 'error');
            console.error('Error updating token status:', error);
        } finally {
            setUpdatingTokenId(null);
        }
    };

    return {updatingTokenId, updateTokenStatus};
};