import React, { FC } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { formatTimeLeft } from './formatTimeLeft';
import { Notification } from './notification';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Close } from '@mui/icons-material';

interface NotificationItemProps {
    notification: Notification;
    onDelete: (id: number) => void;
}

const NotificationItem: FC<NotificationItemProps> = ({notification, onDelete}) => {
    const timeLeft = formatTimeLeft(new Date(notification.createdAt));

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            mb={2}
        >
            <Box display="flex" alignItems="center" gap={2}>
                <Box display='flex' alignItems='center' justifyContent='center' height='41px' width='41px'
                     border='0.5px solid rgba(21, 21, 33, 0.2)' borderRadius='50%'>
                    <NotificationsIcon sx={{color: 'rgba(57, 57, 67, 1)'}}/>
                </Box>
                <Stack gap={1}
                >
                    <Typography fontSize={14} fontWeight={600} mb={0}>
                        {notification.text}
                    </Typography>
                    {notification.tokenName && <Typography mb={0} fontSize={10} fontWeight={500} sx={{color: 'rgba(21, 21, 33, 0.5)'}}>
                        {notification.tokenName} at {notification.tokenMerchantName}
                    </Typography>}
                    {notification.message && <Typography mb={0} fontSize={10}>
                        Message from {notification.senderUsername}:<span
                        style={{fontWeight: 600}}> {notification.message}</span>

                    </Typography>}
                </Stack>
            </Box>

            <Stack alignItems="center">
                <Typography variant="caption" color="text.secondary">
                    {timeLeft}
                </Typography>

                <IconButton
                    aria-label="delete"
                    onClick={() => onDelete(notification.id)}
                    size="small"
                >
                    <Close sx={{fontSize: '16px'}}/>
                </IconButton>
            </Stack>
        </Box>
    );
};

export default NotificationItem;