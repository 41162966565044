import React, { useState } from 'react';

interface UseFormOptions<T> {
    initialValues: T;
    validate: (values: T) => Partial<Record<keyof T, boolean>>;
}

export const useForm = <T extends Record<string, any>>({
                                                           initialValues,
                                                           validate,
                                                       }: UseFormOptions<T>) => {
    const [formData, setFormData] = useState(initialValues);
    const [formTouched, setFormTouched] = useState(
        Object.keys(initialValues).reduce((acc, key) => {
            acc[key as keyof T] = false;
            return acc;
        }, {} as Record<keyof T, boolean>)
    );
    const [isFormValid, setIsFormValid] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setFormData(prevState => {
            const newFormData = { ...prevState, [id]: newValue };
            const validationResults = validate(newFormData);

            const isValid = Object.keys(formData).every(
                key => validationResults[key as keyof T] !== false
            );

            setIsFormValid(isValid);
            return newFormData;
        });
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { id } = e.target;
        setFormTouched(prevState => ({
            ...prevState,
            [id]: true,
        }));
    };

    return {
        formData,
        formTouched,
        isFormValid,
        handleInputChange,
        handleBlur,
        setFormData,
    };
};