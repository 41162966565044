import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { Role } from './PrivateRoute';
import Loading from './Loading';

const HomeRedirect: React.FC = () => {
    const { user, loading } = useContext(UserContext);

    if (loading) {
        return <Loading />;
    }

    if (!user) {
        return <Navigate to="/login" />;
    }

    if (user.role === Role.Merchant) {
        return <Navigate to="/merchant/home" />;
    }

    if (user.role === Role.User) {
        return <Navigate to="/home" />;
    }

    return <Navigate to="/login" />;
};

export default HomeRedirect;