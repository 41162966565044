import React from 'react';
import { Avatar, Box, Button, Chip, Fab, Stack, TextField, Typography } from '@mui/material';
import { Check, Close, ImageOutlined, QrCode2 as QrCode2Icon } from '@mui/icons-material';
import { BoughtToken, UserTokenStatus } from './MyTokens';

interface TokenItemProps {
    userToken: BoughtToken;
    status: UserTokenStatus;
    onApprove?: (token: BoughtToken) => void;
    onDecline?: (token: BoughtToken) => void;
    onUse?: (token: BoughtToken) => void;
    message?: string;
    onMessageChange?: (tokenId: number, message: string) => void;
    navigate?: any;
    loading?: boolean;
}

const TokenItem: React.FC<TokenItemProps> = ({
                                                 userToken,
                                                 status,
                                                 onApprove,
                                                 onDecline,
                                                 onUse,
                                                 message,
                                                 onMessageChange,
                                                 navigate,
                                                 loading
                                             }) => {
    return (
        <Box
            mb={2}
            p={2}
            sx={{
                borderRadius: '12px',
                border: status === UserTokenStatus.Spinning ? '0.5px solid rgba(0, 0, 0, 0.3)' : '',
                bgcolor: status !== UserTokenStatus.Spinning ? 'rgba(245, 245, 245, 1)' : '',
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Avatar
                        sx={{
                            bgcolor: 'rgba(57, 57, 67, 1)',
                            mr: 2,
                            border: userToken.token.imageUrl ? '' : '1px solid black',
                            height: 40,
                            width: 40,
                        }}
                        src={userToken.token.imageUrl || ''}
                    >
                        {!userToken.token.imageUrl && <ImageOutlined sx={{color: 'white'}}/>}
                    </Avatar>
                    <Stack>
                        <Typography fontWeight={600} sx={{color: 'rgba(26, 29, 37, 1)'}}>
                            {userToken.token.itemName} ({userToken.quantity})
                        </Typography>
                        <Typography fontWeight={500} fontSize={10} sx={{color: 'rgba(21, 21, 33, 0.5)'}}>
                            {userToken.token.merchant.username}
                        </Typography>
                    </Stack>
                </Box>
                {status === UserTokenStatus.Spinning && (
                    <Box display="flex" gap={2}>
                        <Fab
                            size="small"
                            sx={{
                                bgcolor: 'rgba(59, 186, 79, 1)',
                                color: 'white',
                                minHeight: 32,
                                minWidth: 32,
                                height: 32,
                                width: 32,
                                zIndex: 100,
                                boxShadow: 'none',
                                '&:hover': {
                                    bgcolor: 'rgba(59, 186, 79, 0.7)',
                                }
                            }}
                            onClick={() => onApprove && onApprove(userToken)}
                            disabled={loading}
                        >
                            <Check sx={{fontSize: '18px'}}/>
                        </Fab>
                        <Fab
                            size="small"
                            sx={{
                                bgcolor: 'rgba(245, 65, 53, 1)',
                                color: 'white',
                                minHeight: 32,
                                minWidth: 32,
                                height: 32,
                                width: 32,
                                zIndex: 100,
                                boxShadow: 'none',
                                '&:hover': {
                                    bgcolor: 'rgba(245, 65, 53, 0.7)',
                                }
                            }}
                            onClick={() => onDecline && onDecline(userToken)}
                            disabled={loading}
                        >
                            <Close sx={{fontSize: '18px'}}/>
                        </Fab>
                    </Box>
                )}
                {status === UserTokenStatus.Tossed && (
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{height: 55, width: 55, minWidth: 55, borderRadius: '12px'}}
                        onClick={() => onUse && onUse(userToken)}
                    >
                        <QrCode2Icon/>
                    </Button>
                )}
                {status === UserTokenStatus.Declined && (
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{height: 55, width: 55, minWidth: 55, borderRadius: '12px'}}
                        onClick={() =>
                            navigate('/proceed-to-payment', {
                                state: {
                                    token: userToken.token,
                                    merchant: userToken.token.merchant,
                                    boughtToken: userToken,
                                },
                            })
                        }
                    >
                        <Typography fontSize={12} fontWeight={500} color="white" mb={0}>
                            Toss
                        </Typography>
                    </Button>
                )}
            </Box>
            {status === UserTokenStatus.Spinning && onMessageChange && (
                <Stack ml={7} mt={1.5}>
                    <Box display={'flex'} gap={1}>
                        {['Thanks!', 'No, Thank you'].map((text) => <Chip
                            label={text}
                            key={text}
                            size="small"
                            sx={{
                                fontSize: '10px',
                                fontWeight: '500',
                                borderRadius: '9px',
                                border: '1px solid rgba(0, 0, 0, 0.5)',
                                backgroundColor: 'white',
                                color: 'rgba(28, 27, 31, 1) !important',
                                '&:hover': {
                                    backgroundColor: 'rgba(245, 245, 245, 1)',
                                }
                            }}
                            onClick={() => {
                                onMessageChange(userToken.id, message + text);
                            }}
                        />)}
                    </Box>
                    <TextField
                        placeholder="Message (optional)"
                        value={message || ''}
                        onChange={(e) => onMessageChange(userToken.id, e.target.value)}
                        margin="normal"
                        multiline
                        minRows={2}
                        sx={{
                            mt: 1,
                            width: '-webkit-fill-available',
                            '& .MuiInputBase-root': {
                                height: 'auto',
                            },
                            '& .MuiInputBase-input': {
                                maxHeight: '50px',
                            },
                        }}
                    />
                </Stack>
            )}
        </Box>
    );
};

export default TokenItem;