import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { BoughtToken } from './MyTokens';

interface UseTokenDialogProps {
    tokenToConsume: BoughtToken | null;
    onClose: () => void;
    onConfirm: (token: BoughtToken) => void;
}

const UseTokenDialog: React.FC<UseTokenDialogProps> = ({ tokenToConsume, onClose, onConfirm }) => {
    return (
        <Dialog open={!!tokenToConsume} onClose={onClose}>
            <DialogTitle>Use token</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to use this token?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        backgroundColor: 'white',
                        fontWeight: 'bold',
                        color: '#2E2E36',
                        height: '48px',
                        borderRadius: '12px',
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    }}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    sx={{
                        backgroundColor: '#2E2E36',
                        color: '#fff',
                        fontWeight: 'medium',
                        height: '48px',
                        borderRadius: '12px',
                        '&:hover': {
                            backgroundColor: '#26262e',
                        },
                    }}
                    onClick={() => tokenToConsume && onConfirm(tokenToConsume)}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UseTokenDialog;