import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Chip, CircularProgress, Typography, } from '@mui/material';
import throttle from 'lodash/throttle';
import { Close } from '@mui/icons-material';
import { UserContext } from '../../../context/UserContext';
import { NotificationsContext } from '../../../context/NotificationContext';
import useSnackbar from '../../../hooks/useSnackbar';
import api from '../../../services/api';
import BasePageLayout from '../../../components/BasePageLayout';
import { Notification } from '../../merchant/notifications/notification';
import NotificationItem from '../../merchant/notifications/NotificationItem';

const NotificationsPage: React.FC = () => {
    const {user} = useContext(UserContext);
    const {setNotificationCount} = useContext(NotificationsContext);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const {showSnackbar, SnackbarComponent} = useSnackbar();

    const fetchNotifications = useCallback(
        async (pageNumber: number, reset: boolean = false) => {
            if (!user) {
                return;
            }

            setLoading(true);
            try {
                const response = await api.get('/notifications', {
                    params: {
                        sort: ['createdAt:desc'],
                        pagination: {
                            page: pageNumber,
                            pageSize: 10,
                        },
                        filters: {
                            user: { id: user.id },
                        },
                    },
                });

                const fetchedNotifications: Notification[] = response.data.data.map((item: any) => ({
                    id: item.id,
                    ...item.attributes
                }));

                const {pageCount} = response.data.meta.pagination;
                setPageCount(pageCount);

                setNotifications((prev) => (reset ? fetchedNotifications : [...prev, ...fetchedNotifications]));
            } catch (err) {
                setError('Error fetching notifications.');
                showSnackbar('Error fetching notifications.', 'error');
                console.error('Error fetching notifications:', err);
            } finally {
                setLoading(false);
            }
        },
        [user, showSnackbar]
    );

    useEffect(() => {
        fetchNotifications(1, true);
    }, [fetchNotifications]);

    useEffect(() => {
        const handleScroll = throttle(() => {
            if (
                window.innerHeight + document.documentElement.scrollTop + 100 >=
                document.documentElement.scrollHeight &&
                !loading &&
                page < pageCount
            ) {
                setPage((prev) => prev + 1);
            }
        }, 200);

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            handleScroll.cancel();
        };
    }, [loading, page, pageCount]);

    useEffect(() => {
        if (page > 1) {
            fetchNotifications(page);
        }
    }, [page, fetchNotifications]);

    const handleDelete = async (id: number) => {
        try {
            setNotifications((prev) => prev.filter((notif) => notif.id !== id));
            setNotificationCount((prev) => (prev > 0 ? prev - 1 : 0));
            await api.delete(`/notifications/${id}`);
        } catch (err) {
            setError('Error deleting notification.');
            showSnackbar('Error deleting notification.', 'error');
            console.error('Error deleting notification:', err);
        }
    };

    const handleClearAll = async () => {
        setDeleting(true);
        try {
            const deletePromises = notifications.map((notif) => api.delete(`/notifications/${notif.id}`));

            await Promise.all(deletePromises);

            setNotifications([]);
            setNotificationCount(0);
            showSnackbar('All notifications deleted.', 'success');
        } catch (err) {
            setError('Error deleting all notifications.');
            showSnackbar('Error deleting all notifications.', 'error');
            console.error('Error deleting all notifications:', err);
        } finally {
            setDeleting(false);
        }
    };


    return (
        <BasePageLayout>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography fontSize={16} mb={0}>Notifications</Typography>
                {notifications.length > 0 && (
                    <Chip
                        label="Clear All"
                        onDelete={handleClearAll}
                        deleteIcon={<Close sx={{color: 'white !important'}}/>}
                        color="error"
                        size="small"
                        sx={{cursor: 'pointer'}}
                    />
                )}
            </Box>

            {notifications.map((notification) => (
                <NotificationItem
                    key={notification.id}
                    notification={notification}
                    onDelete={handleDelete}
                />
            ))}

            {loading && (
                <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress/>
                </Box>
            )}

            {deleting && (
                <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress color="error"/>
                </Box>
            )}

            {error && (
                <Typography color="error" mt={2}>
                    {error}
                </Typography>
            )}

            {!loading && notifications.length === 0 && (
                <Typography>No notifications available.</Typography>
            )}

            <SnackbarComponent/>
        </BasePageLayout>
    );
};

export default NotificationsPage;