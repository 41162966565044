import React, { useContext, useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { UserContext } from '../../context/UserContext';
import useSnackbar from '../../hooks/useSnackbar';
import { formatPhoneNumber, validateEmail, validatePhoneNumber, validateUsername } from '../../services/utils';
import { useForm } from '../../hooks/useForm';
import api, { API_URL, jwtTokenKey } from '../../services/api';
import BasePageLayout from '../../components/BasePageLayout';
import { PhoneInputMask } from '../user/auth/PhoneInputMask';
import { readAndCompressImage } from 'browser-image-resizer';

const MerchantSettingsPage: React.FC = () => {
    const {user, avatarUrl, updateUser} = useContext(UserContext);
    const [updating, setUpdating] = useState<boolean>(false);
    const [uploadingAvatar, setUploadingAvatar] = useState<boolean>(false);
    const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false);
    const navigate = useNavigate();
    const {showSnackbar, SnackbarComponent} = useSnackbar();

    const initialFormValues = {
        username: '',
        address: '',
        supportPhoneNumber: '',
        supportEmail: '',
        accountEmail: '',
        avatar: undefined as number | undefined,
    };

    const validate = (values: typeof initialFormValues) => ({
        username: values.username.length >= 3,
        supportPhoneNumber:
            !values.supportPhoneNumber || validatePhoneNumber(values.supportPhoneNumber),
        supportEmail: !values.supportEmail || validateEmail(values.supportEmail),
    });

    const {
        formData,
        formTouched,
        isFormValid,
        handleInputChange,
        handleBlur,
        setFormData,
    } = useForm({
        initialValues: initialFormValues,
        validate,
    });

    useEffect(() => {
        if (user) {
            setFormData({
                username: user.username || '',
                address: user.address || '',
                supportPhoneNumber: user.supportPhoneNumber ? user.supportPhoneNumber.slice(2) : '',
                supportEmail: user.supportEmail || '',
                accountEmail: user.email || '',
                avatar: user.avatar?.id,
            });
        }
    }, [user, setFormData]);

    const handleAvatarUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) {
            showSnackbar('No file selected', 'error');
            return;
        }

        const config = {
            quality: 1,
            maxWidth: 800,
            maxHeight: 800,
            autoRotate: true
        };

        try {
            const correctedFile = await readAndCompressImage(file, config);

            const formData = new FormData();
            formData.append('files', correctedFile, file.name);
            formData.append('ref', 'plugin::users-permissions.user');
            formData.append('refId', String(user?.id));
            formData.append('field', 'avatar');

            setUploadingAvatar(true);

            const response = await axios.post<{ id: number; url: string }[]>(
                `${API_URL}/api/upload`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(jwtTokenKey)}`,
                    },
                }
            );

            const uploadedFile = response.data[0];
            setFormData((prevState) => ({
                ...prevState,
                avatar: uploadedFile.id,
            }));

            updateUser({
                avatar: {
                    id: uploadedFile.id,
                    url: uploadedFile.url,
                },
            });

            showSnackbar('Profile picture uploaded successfully', 'success');
        } catch (error) {
            showSnackbar('Failed to upload profile picture', 'error');
            console.error('Error uploading profile picture:', error);
        } finally {
            setUploadingAvatar(false);
        }
    };

    const handleUpdateProfile = async () => {
        if (!user?.id) {
            showSnackbar('User ID is not available', 'error');
            return;
        }

        setUpdating(true);
        try {
            const updatedData = {
                username: formData.username,
                address: formData.address,
                supportPhoneNumber: formatPhoneNumber(formData.supportPhoneNumber),
                supportEmail: formData.supportEmail,
                avatar: formData.avatar as any,
            };

            await api.put(`/users/${user.id}`, updatedData);

            updateUser(updatedData);

            showSnackbar('Profile updated successfully', 'success');
            navigate('/merchant/home');
        } catch (error) {
            showSnackbar('Failed to update profile', 'error');
            console.error('Error updating profile:', error);
        } finally {
            setUpdating(false);
        }
    };

    const handleLogout = () => {
        setOpenLogoutDialog(true);
    };

    const handleConfirmLogout = () => {
        setOpenLogoutDialog(false);
        showSnackbar('Logged out successfully', 'success');
        localStorage.removeItem(jwtTokenKey);
        updateUser(null);
        navigate('/merchant/login');
    };

    const handleCancelLogout = () => {
        setOpenLogoutDialog(false);
    };

    return (
        <BasePageLayout title="Edit Profile" showNotifications={false}>
            <Box component="form" width="100%">
                <>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mb={3}
                        mt={1}
                        ml={uploadingAvatar ? 0 : 6}
                        position="relative"
                    >
                        {uploadingAvatar ? (
                            <Box sx={{height: '120px', display: 'flex', alignItems: 'center'}}>
                                <CircularProgress/>
                            </Box>
                        ) : (
                            <>
                                <Avatar
                                    src={avatarUrl || ''}
                                    sx={{
                                        width: 120,
                                        height: 120,
                                        border: '1px solid #FFA500',
                                    }}
                                />

                                <label htmlFor="icon-button-file">
                                    <Input
                                        id="icon-button-file"
                                        type="file"
                                        inputProps={{accept: "image/*"}}
                                        sx={{display: 'none', position: 'absolute'}}
                                        onChange={handleAvatarUpload}
                                    />
                                    <IconButton
                                        color="secondary"
                                        aria-label="upload picture"
                                        component="span"
                                        sx={{
                                            position: 'relative',
                                            bottom: -49,
                                            left: -40,
                                            backgroundColor: 'transparent ',
                                            borderRadius: '50%',
                                            padding: '12px',
                                        }}
                                    >
                                        <PhotoCamera style={{color: '#FF8C00', fontSize: 24}}/>
                                    </IconButton>
                                </label>
                            </>
                        )}
                    </Box>

                    <FormControl fullWidth sx={{marginBottom: '20px'}}>
                        <Typography fontSize={16}>Name</Typography>
                        <TextField
                            id="username"
                            value={formData.username}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={!validateUsername(formData.username) && formTouched.username}
                            helperText={
                                !validateUsername(formData.username) && formTouched.username
                                    ? 'Username must be at least 3 characters long'
                                    : ''
                            }
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{marginBottom: '20px'}}>
                        <Typography fontSize={16}>Address</Typography>
                        <TextField
                            id="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={formTouched.address && !formData.address}
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{marginBottom: '20px'}}>
                        <Typography fontSize={16}>Support Phone Number</Typography>
                        <TextField
                            id="supportPhoneNumber"
                            placeholder={'(___)-___-____'}
                            value={formData.supportPhoneNumber}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={
                                !validatePhoneNumber(formData.supportPhoneNumber) && formTouched.supportPhoneNumber
                            }
                            helperText={
                                !validatePhoneNumber(formData.supportPhoneNumber) && formTouched.supportPhoneNumber
                                    ? 'Invalid phone number format'
                                    : ''
                            }
                            InputProps={{
                                inputComponent: PhoneInputMask,
                                startAdornment: (
                                    <InputAdornment position="start"><Typography
                                        mb={0}>+1</Typography></InputAdornment>)
                            }}
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{marginBottom: '20px'}}>
                        <Typography fontSize={16}>Support Email</Typography>
                        <TextField
                            id="supportEmail"
                            value={formData.supportEmail}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={!validateEmail(formData.supportEmail) && formTouched.supportEmail}
                            helperText={
                                !validateEmail(formData.supportEmail) && formTouched.supportEmail
                                    ? 'Please enter a valid email address'
                                    : ''
                            }
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{marginBottom: '20px'}}>
                        <Typography fontSize={16}>Account Email</Typography>
                        <TextField id="accountEmail" value={formData.accountEmail} disabled/>
                    </FormControl>

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleUpdateProfile}
                        disabled={!isFormValid || updating}
                        sx={{mb: 2}}
                    >
                        {updating ? <CircularProgress size={24}/> : 'Update Profile'}
                    </Button>

                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={handleLogout}
                        startIcon={<LogoutIcon/>}
                        sx={{
                            backgroundColor: 'white',
                            color: 'rgba(57, 57, 67, 1)',
                            fontWeight: 600,
                            border: '1px solid rgba(57, 57, 67, 1)',
                            '&:hover': {
                                backgroundColor: 'rgba(57, 57, 67, 0.05)',
                            },
                            mb: 2,
                        }}
                    >
                        Log Out
                    </Button>

                    <Dialog
                        open={openLogoutDialog}
                        onClose={handleCancelLogout}
                        PaperProps={{
                            sx: {
                                borderRadius: '16px',
                                padding: '24px',
                                textAlign: 'center',
                                minWidth: '320px',
                            },
                        }}
                    >
                        <DialogTitle>
                            <Typography fontWeight="bold" fontSize="24px">
                                Are you sure?
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                component="div"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mb: 2,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={handleConfirmLogout}
                                    sx={{
                                        backgroundColor: '#2E2E36',
                                        color: '#fff',
                                        fontWeight: 'medium',
                                        height: '48px',
                                        borderRadius: '12px',
                                        marginBottom: '12px',
                                        '&:hover': {
                                            backgroundColor: '#26262e',
                                        },
                                    }}
                                >
                                    Log Out
                                </Button>
                            </Box>
                            <Button
                                fullWidth
                                onClick={handleCancelLogout}
                                sx={{
                                    backgroundColor: 'white',
                                    fontWeight: 'bold',
                                    color: '#2E2E36',
                                    height: '48px',
                                    borderRadius: '12px',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                    },
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogContent>
                    </Dialog>
                </>
                <SnackbarComponent/>
            </Box>
        </BasePageLayout>
    );
};

export default MerchantSettingsPage;