import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomeRedirect from '../components/HomeRedirect';
import MerchantLoginPage from '../pages/merchant/auth/MerchantLoginPage';
import MerchantSignUpPage from '../pages/merchant/auth/MerchantSignUpPage';
import ForgotPasswordPage from '../pages/merchant/auth/ForgotPasswordPage';
import SetNewPasswordPage from '../pages/merchant/auth/SetNewPasswordPage';
import UserLoginPage from '../pages/user/auth/UserLoginPage';
import UserSignUpPage from '../pages/user/auth/UserSignUpPage';
import PhoneVerificationPage from '../pages/user/auth/PhoneVerificationPage';
import PrivateRoute, { Role } from '../components/PrivateRoute';
import MerchantHome from '../pages/merchant/home/MerchantHome';
import MerchantTransactionsPage from '../pages/merchant/transactions/MerchantTransactions';
import MerchantSettingsPage from '../pages/merchant/MerchantSettings';
import IssueTokensPage from '../pages/merchant/tokens/IssueTokensPage';
import TokensPage from '../pages/merchant/tokens/Tokens';
import TokenDetailsPage from '../pages/merchant/tokens/TokenDetailsPage';
import Home from '../pages/user/home/Home';
import Settings from '../pages/user/Settings';
import Notifications from '../pages/user/notifications/Notifications';
import Toss from '../pages/user/Toss';
import ProceedToPayment from '../pages/user/ProceedToPayment';
import AllRight from '../pages/user/AllRight';
import MyTokens from '../pages/user/my-tokens/MyTokens';
import UserTransactions from '../pages/user/transactions/UserTransactions';
import ScanQr from '../pages/user/ScanQr';

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<HomeRedirect/>}/>

            <Route path="/merchant/login" element={<MerchantLoginPage/>}/>
            <Route path="/merchant/signup" element={<MerchantSignUpPage/>}/>
            <Route path="/merchant/forgot-password" element={<ForgotPasswordPage/>}/>
            <Route path="/merchant/reset-password" element={<SetNewPasswordPage/>}/>

            <Route path="/login" element={<UserLoginPage/>}/>
            <Route path="/signup" element={<UserSignUpPage/>}/>
            <Route path="/verify-phone" element={<PhoneVerificationPage/>}/>

            <Route
                path="/merchant/home"
                element={<PrivateRoute element={<MerchantHome/>} role={Role.Merchant}/>}
            />
            <Route
                path="/merchant/transactions"
                element={<PrivateRoute element={<MerchantTransactionsPage/>} role={Role.Merchant}/>}
            />
            <Route
                path="/merchant/settings"
                element={<PrivateRoute element={<MerchantSettingsPage/>} role={Role.Merchant}/>}
            />
            <Route
                path="/merchant/issue-tokens"
                element={<PrivateRoute element={<IssueTokensPage/>} role={Role.Merchant}/>}
            />
            <Route
                path="/merchant/tokens"
                element={<PrivateRoute element={<TokensPage/>} role={Role.Merchant}/>}
            />
            <Route
                path="/merchant/tokens/:id"
                element={<PrivateRoute element={<TokenDetailsPage/>} role={Role.Merchant}/>}
            />
            <Route
                path="/merchant/notifications"
                element={<PrivateRoute element={<Notifications/>} role={Role.Merchant}/>}
            />

            <Route
                path="/home"
                element={<PrivateRoute element={<Home/>} role={Role.User}/>}
            />
            <Route
                path="/transactions"
                element={<PrivateRoute element={<UserTransactions/>} role={Role.User}/>}
            />
            <Route
                path="/settings"
                element={<PrivateRoute element={<Settings/>} role={Role.User}/>}
            />
            <Route
                path="/notifications"
                element={<PrivateRoute element={<Notifications/>} role={Role.User}/>}
            />
            <Route
                path="/toss"
                element={<PrivateRoute element={<Toss/>} role={Role.User}/>}
            />
            <Route
                path="/proceed-to-payment"
                element={<PrivateRoute element={<ProceedToPayment/>} role={Role.User}/>}
            />
            <Route
                path="/all-right"
                element={<PrivateRoute element={<AllRight/>} role={Role.User}/>}
            />
            <Route
                path="/scan-qr"
                element={<PrivateRoute element={<ScanQr/>} role={Role.User}/>}
            />
            <Route
                path="/my-tokens"
                element={<PrivateRoute element={<MyTokens/>} role={Role.User}/>}
            />
        </Routes>
    );
};

export default AppRoutes;