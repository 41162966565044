import axios from 'axios';
import { Role } from '../components/PrivateRoute';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { User } from '../context/UserContext';

export const jwtTokenKey = 'jwtToken';

// export const API_URL = 'http://localhost:1337';
export const API_URL = 'https://api-dev.tokntoss.com';

const api = axios.create({
    baseURL: `${API_URL}/api`,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default api;

api.interceptors.request.use(
    (config) => {
        const jwtToken = localStorage.getItem(jwtTokenKey);
        if (jwtToken) {
            config.headers.Authorization = `Bearer ${jwtToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const authenticateWithFirebase = async (
    firebaseIdToken: string,
    additionalData?: {
        username?: string;
        email?: string;
        phoneNumber?: string;
        role?: StrapiRole | string;
    }
) => {
    try {
        const response = await api.post('/firebase-auth', {
            idToken: firebaseIdToken,
            profileMetaData: {...additionalData},
        });
        const {jwt, user} = response.data;

        localStorage.setItem(jwtTokenKey, jwt);

        return user;
    } catch (error: any) {
        console.error('Firebase authentication error:', error);
        throw error.response?.data || error.message;
    }
};

interface LoginData {
    identifier: string;
    password: string;
}

export const login = async (data: LoginData) => {
    try {
        const response = await api.post('/auth/local', data);
        const {jwt, user} = response.data;

        localStorage.setItem(jwtTokenKey, jwt);

        return user;
    } catch (error: any) {
        console.error('Login error:', error);
        throw error.response?.data || error.message;
    }
};

export enum StrapiRole {
    User = 1,
    Merchant = 3
}

export const updateUserRole = async (userId: number, role: Role | number) => {
    try {
        await api.put(`/users/${userId}`, {role});
    } catch (error: any) {
        console.error('Error updating user role:', error);
        throw error.response?.data || error.message;
    }
};

export const updateUser = async (userId: number, data: Partial<User>) => {
    try {
        const updatedData = {
            username: data.username,
            role: data.role
        };

        await api.put(`/users/${userId}`, updatedData);
    } catch (error) {
        console.error('Error updating profile:', error);
        throw new Error('Failed to update profile');
    }
};

export const sendPasswordResetEmailHandler = async (email: string) => {
    try {
        const auth = getAuth();
        const response: any = await sendPasswordResetEmail(auth, email);
        return response?.data;
    } catch (error) {
        console.error("Password reset error:", error);
        if (axios.isAxiosError(error)) {
            throw error.response?.data || error.message;
        } else {
            throw new Error("An unexpected error occurred");
        }
    }
};

export const resetPassword = async (password: string, confirmPassword: string, code: string | null) => {
    try {
        const response = await api.post('/auth/reset-password', {
            code,
            password,
            passwordConfirmation: confirmPassword,
        });
        return response.data;
    } catch (error: any) {
        console.error('Reset password error:', error);
        throw error.response?.data || error.message;
    }
};