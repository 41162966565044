import React, { createContext, useState, ReactNode } from 'react';

interface NotificationsContextType {
    notificationCount: number;
    setNotificationCount: React.Dispatch<React.SetStateAction<number>>;
}

export const NotificationsContext = createContext<NotificationsContextType>({
    notificationCount: 0,
    setNotificationCount: () => {
    },
});

interface NotificationsProviderProps {
    children: ReactNode;
}

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({children}) => {
    const [notificationCount, setNotificationCount] = useState<number>(0);

    return (
        <NotificationsContext.Provider value={{notificationCount, setNotificationCount}}>
            {children}
        </NotificationsContext.Provider>
    );
};