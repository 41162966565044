import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Link, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { UserContext } from '../../../context/UserContext';
import { auth } from '../../../services/firebaseConfig';
import api, { authenticateWithFirebase } from '../../../services/api';
import BackButton from '../../../components/BackButton';

const PhoneVerificationPage: React.FC = () => {
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [error, setError] = useState<string | null>(null);
    const [timer, setTimer] = useState(60);
    const navigate = useNavigate();
    const location = useLocation();
    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

    let confirmationResult = window.confirmationResult as ConfirmationResult;
    const {username, phoneNumber} = location.state || {};
    const {fetchUser} = useContext(UserContext);

    useEffect(() => {
        if (!confirmationResult || !phoneNumber) {
            navigate('/login');
        }
    }, [confirmationResult, navigate, phoneNumber]);

    useEffect(() => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible',
                'callback': () => {
                },
                'expired-callback': () => {
                    window.recaptchaVerifier.clear();
                },
            });
        }
    }, []);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [timer]);

    const handleResendCode = async () => {
        setError(null);
        try {
            if (!window.recaptchaVerifier) {
                window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                    'size': 'invisible',
                });
            }
            const appVerifier = window.recaptchaVerifier;
            confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
            window.confirmationResult = confirmationResult;
            setTimer(60);
        } catch (err) {
            setError('Failed to resend verification code. Please try again.');
        }
    };

    const handleChange = (index: number, value: string) => {
        if (!/^\d*$/.test(value)) {
            return;
        }
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value && index < 5) {
            inputRefs.current[index + 1]?.focus();
        } else if (!value && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('Text').slice(0, 6);
        if (!/^\d{6}$/.test(pasteData)) {
            return;
        }
        const newCode = pasteData.split('');
        setCode(newCode);
        inputRefs.current[5]?.focus();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            setCode(prevCode => {
                const newCode = [...prevCode];
                newCode[index - 1] = '';
                return newCode;
            });
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handleVerifyCode = async () => {
        const codeStr = code.join('');
        if (codeStr.length !== 6) {
            setError('Please enter the 6-digit code.');
            return;
        }
        try {
            const result = await confirmationResult.confirm(codeStr);
            const firebaseUser = result.user;
            const idToken = await firebaseUser.getIdToken();

            const user = await authenticateWithFirebase(idToken, {
                phoneNumber: firebaseUser.phoneNumber || '',
            });

            await api.put(`/users/${user.id}`, {username});

            await fetchUser();

            navigate('/home');
        } catch (err) {
            setError('Wrong code, please try again');
        }
    };

    return (
        <Container maxWidth="xs" sx={{px: 3}}>
            <Box
                display="flex"
                flexDirection="column"
                minHeight="100dvh"
            >
                <Box width={1} minHeight="70dvh" display="flex" flexDirection="column" justifyContent="space-between">
                    <Box>
                        <Box sx={{py: 3}}>
                            <BackButton/>
                        </Box>

                        <Typography variant="h1" align="left" mb={3}>
                            Verify
                        </Typography>

                        <Typography fontSize={16} sx={{color: 'rgba(0, 0, 0, 0.7)', mb: 4}}>
                            Please enter the code sent in SMS to complete sign-in
                        </Typography>

                        <Box display="flex" justifyContent="space-between" gap={1}>
                            {code.map((value, index) => (
                                <TextField
                                    key={index}
                                    inputRef={(el) => (inputRefs.current[index] = el)}
                                    value={value}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    onPaste={handlePaste}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    error={!!error}
                                    inputMode="numeric"
                                    type="tel"
                                    inputProps={{
                                        maxLength: 1,
                                        style: {textAlign: 'center'}
                                    }}
                                    sx={{
                                        width: '57px',
                                        height: '65px',
                                        fontSize: '24px',
                                        '& .MuiInputBase-root': {height: '65px', fontSize: '24px', fontWeight: 700}
                                    }}
                                />
                            ))}
                        </Box>

                        {error && <Typography color="error" sx={{marginTop: '40px'}} fontSize={16}
                                              textAlign='center'>{error}</Typography>}

                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 5, marginTop: '20px'}}>
                        {timer > 0 ? (
                            <Typography variant="body2" align="center" sx={{color: 'rgba(45, 45, 45, 1)'}}>
                                Resend in {timer}
                            </Typography>
                        ) : (
                            <Link variant="body2" textAlign='center' fontSize={16} onClick={handleResendCode}>
                                Resend code
                            </Link>
                        )}

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={code.join('').length !== 6}
                            onClick={handleVerifyCode}
                            sx={{marginTop: '30px'}}
                        >
                            Finish
                        </Button>
                    </Box>
                </Box>
                <div id="recaptcha-container"></div>
            </Box>
        </Container>
    );
};

export default PhoneVerificationPage;