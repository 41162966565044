import React, { useContext, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Fab,
    IconButton,
    Input,
    LinearProgress,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Close, ImageOutlined } from '@mui/icons-material';
import axios from 'axios';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { useNavigate } from 'react-router-dom';
import { Token } from './TokenDetailsPage';
import { UserContext } from '../../../context/UserContext';
import useSnackbar from '../../../hooks/useSnackbar';
import api, { API_URL, jwtTokenKey } from '../../../services/api';
import BasePageLayout from '../../../components/BasePageLayout';
import { datePickerStyles } from '../../../styles/date-picker-styles';

const IssueTokensPage: React.FC = () => {
    const [formData, setFormData] = useState({
        itemName: '',
        itemDescription: '',
        quantity: 25,
        price: '' as number | string,
        discount: 0,
        expirationDate: null as Date | null,
        imageFile: null as File | null,
        imageUrl: null as string | null,
    } as Token);

    const [formTouched, setFormTouched] = useState({
        itemName: false,
        itemDescription: false,
        quantity: false,
        price: false,
        expirationDate: false,
        imageUrl: false,
    });

    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const { showSnackbar, SnackbarComponent } = useSnackbar();
    const navigate = useNavigate();

    const handleBlur = (field: keyof typeof formTouched) => {
        setFormTouched((prev) => ({ ...prev, [field]: true }));
    };

    const isExpirationDateValid = (date: Date | null) => {
        if (!date) return false;
        const now = new Date();
        return date > now;
    };

    const validate = () => {
        const { itemName, itemDescription, quantity, price, expirationDate } = formData;
        return {
            itemName: !!itemName,
            itemDescription: !!itemDescription,
            quantity: quantity > 24,
            price: price && +price > 0,
            expirationDate: expirationDate ? isExpirationDateValid(expirationDate) : false,
        };
    };

    const isFormValid = Object.values(validate()).every(Boolean);

    const handleImageUpload = async (file: File) => {
        const maxSizeInMB = 10;
        const maxSize = maxSizeInMB * 1024 * 1024;

        setUploadError(null);

        if (file.size > maxSize) {
            setUploadError(`File size exceeds ${maxSizeInMB} MB. Please upload a smaller image.`);
            return;
        }

        const formData = new FormData();
        formData.append('files', file);

        try {
            setFormData((prev) => ({
                ...prev,
                imageFile: file,
            }));

            setUploading(true);
            const response = await axios.post(`${API_URL}/api/upload`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(jwtTokenKey)}`,
                },
            });

            setFormData((prev) => ({
                ...prev,
                imageUrl: response.data[0].url,
            }));

            showSnackbar('Image uploaded successfully', 'success');
        } catch (error) {
            console.error('Error uploading image', error);
            setUploadError('Failed to upload image. Please try again.');
        } finally {
            setUploading(false);
        }
    };

    const handleSubmit = async () => {
        if (!user) {
            return;
        }

        setLoading(true);

        const { itemName, itemDescription, quantity, price, discount, expirationDate, imageUrl } = formData;

        const data = {
            itemName: itemName.trim(),
            itemDescription,
            quantity,
            price,
            discount,
            expirationDate: expirationDate ? expirationDate.toISOString() : null,
            imageUrl,
            merchant: { id: user?.id },
        };

        try {
            const res = await api.get('/tokens', {
                params: {
                    filters: {
                        itemName: { $eq: itemName.trim() },
                        $and: [
                            { isActive: true },
                            { expirationDate: { $gte: new Date().toISOString() } },
                        ],
                        merchant: { id: { $eq: user.id } },
                    },
                },
            });

            if (res.data?.data?.length) {
                showSnackbar(
                    'A token with this name already exists. Please deactivate the existing token or use a different name for the new token.',
                    'error'
                );
                setLoading(false);
                return;
            }

            await api.post(`/tokens`, { data });

            navigate('/merchant/tokens');
            showSnackbar('Tokens issued successfully', 'success');
        } catch (error: any) {
            showSnackbar('Failed to issue tokens', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <BasePageLayout>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt={2}>
                    <Typography variant="h6">Issue Tokens</Typography>
                    <Typography variant="h6" fontWeight={700} fontSize={18.4}>
                        Total Value: $
                        {(
                            +formData.price *
                            formData.quantity *
                            (1 - formData.discount / 100)
                        ).toFixed(2)}
                    </Typography>
                </Box>

                <TextField
                    fullWidth
                    placeholder="Item Name"
                    inputProps={{
                        maxLength: 50,
                    }}
                    value={formData.itemName}
                    onChange={(e) => setFormData({ ...formData, itemName: e.target.value })}
                    onBlur={() => handleBlur('itemName')}
                    error={formTouched.itemName && !formData.itemName}
                    helperText={
                        formTouched.itemName && !formData.itemName ? 'Item Name is required' : ''
                    }
                    margin="normal"
                />
                <TextField
                    fullWidth
                    placeholder="Item Description"
                    inputProps={{
                        maxLength: 255,
                    }}
                    value={formData.itemDescription}
                    onChange={(e) => setFormData({ ...formData, itemDescription: e.target.value })}
                    onBlur={() => handleBlur('itemDescription')}
                    error={formTouched.itemDescription && !formData.itemDescription}
                    helperText={
                        formTouched.itemDescription && !formData.itemDescription
                            ? 'Item Description is required'
                            : ''
                    }
                    margin="normal"
                />

                <Box display="flex" alignItems="baseline" mt={2}>
                    <Typography sx={{ mr: 2, mb: 0, fontWeight: 600 }}>Quantity</Typography>
                    <Fab
                        size="small"
                        disabled={formData.quantity <= 25}
                        sx={{
                            bgcolor: 'rgba(255, 166, 41, 1)',
                            minHeight: 25,
                            minWidth: 25,
                            height: 25,
                            width: 25,
                            fontFamily: 'auto',
                            fontSize: '16px',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'rgba(230, 150, 35, 1)',
                            },
                        }}
                        onClick={() =>
                            setFormData((prev) => ({
                                ...prev,
                                quantity: Math.max(prev.quantity - 1, 1),
                            }))
                        }
                    >
                        <Typography color="white" mb="3px">
                            -
                        </Typography>
                    </Fab>
                    <Typography sx={{ mx: 2, mb: 0, fontWeight: 600 }}>{formData.quantity}</Typography>
                    <Fab
                        sx={{
                            bgcolor: 'rgba(255, 166, 41, 1)',
                            color: 'white',
                            minHeight: 25,
                            minWidth: 25,
                            height: 25,
                            width: 25,
                            fontFamily: 'auto',
                            fontSize: '16px',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'rgba(230, 150, 35, 1)',
                            },
                        }}
                        onClick={() =>
                            setFormData((prev) => ({ ...prev, quantity: prev.quantity + 1 }))
                        }
                    >
                        <Typography color="white" mb="4px">
                            +
                        </Typography>
                    </Fab>
                    <Typography sx={{ ml: 3, mb: 0, fontWeight: 600 }}>Price/ea</Typography>
                    <TextField
                        value={formData.price !== null ? formData.price : ''}
                        onChange={(e) => {
                            const inputValue = e.target.value;

                            setFormData({
                                ...formData,
                                price: inputValue,
                            });
                        }}
                        onBlur={() => {
                            if (formData.price && !isNaN(parseFloat(formData.price.toString()))) {
                                setFormData({
                                    ...formData,
                                    price: parseFloat(formData.price.toString()).toFixed(2),
                                });
                            }
                            handleBlur('price');
                        }}
                        error={formTouched.price && +formData.price <= 0}
                        helperText={
                            formTouched.price && +formData.price <= 0 ? 'Required' : ''
                        }
                        InputProps={{
                            startAdornment: <Typography sx={{ ml: 1, mb: 0 }}>$</Typography>,
                            inputMode: 'decimal',
                        }}
                        sx={{ ml: 2, width: '100px' }}
                    />
                </Box>

                <Box mt={3} mb={2}>
                    <Typography sx={{ mr: 2, fontSize: 16, fontWeight: 400 }}>Discount</Typography>
                    <ToggleButtonGroup
                        exclusive
                        value={formData.discount}
                        onChange={(_, value) => setFormData((prev) => ({ ...prev, discount: value }))}
                        sx={{
                            width: '100%',
                            gap: '16px',
                            '& .MuiToggleButton-root': {
                                borderRadius: '12px',
                                backgroundColor: 'rgba(245, 245, 245, 1)',
                                fontWeight: 600,
                                border: 'none',
                                height: 28,
                                flexGrow: 1,
                                '&.Mui-selected': {
                                    backgroundColor: 'rgba(57, 57, 67, 1)',
                                    color: '#FFFFFF',
                                    border: 'none',
                                    '&:hover': {
                                        backgroundColor: 'rgba(46, 46, 54, 1)',
                                    },
                                },
                                '&:hover': {
                                    backgroundColor: 'rgba(245, 245, 245, 1)',
                                },
                            },
                        }}
                    >
                        {[5, 10, 15, 20].map((discount) => (
                            <ToggleButton key={discount} value={discount}>
                                {discount}%
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: 16, fontWeight: 400 }}>Expiration Date</Typography>
                    <DatePicker
                        label="Date"
                        value={formData.expirationDate ?? null}
                        onChange={(newDate) => {
                            if (newDate) {
                                const expirationDate = new Date(
                                    newDate.getFullYear(),
                                    newDate.getMonth(),
                                    newDate.getDate(),
                                    23,
                                    59,
                                    59
                                );
                                console.log(expirationDate)
                                setFormData((prev) => ({
                                    ...prev,
                                    expirationDate,
                                }));
                            }
                        }}
                        minDate={new Date()}
                        onClose={() => handleBlur('expirationDate')}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                margin: 'normal',
                                error:
                                    formTouched.expirationDate &&
                                    (!formData.expirationDate ||
                                        !isExpirationDateValid(formData.expirationDate)),
                                helperText:
                                    formTouched.expirationDate &&
                                    (!formData.expirationDate
                                        ? 'Expiration date is required'
                                        : !isExpirationDateValid(formData.expirationDate)
                                            ? 'Expiration date cannot be in the past'
                                            : ''),
                            },
                            ...datePickerStyles,
                        }}
                    />
                </Box>

                <Box display="flex" alignItems="center" mt={2}>
                    <label htmlFor="upload-image" style={{ display: 'flex' }}>
                        <Avatar
                            sx={{
                                bgcolor: 'rgba(57, 57, 67, 1)',
                                mr: 2,
                                border: formData.imageUrl ? '' : '1px solid black',
                            }}
                            src={formData.imageUrl || ''}
                        >
                            {!formData.imageUrl && <ImageOutlined sx={{ color: 'white' }} />}
                        </Avatar>
                        {!formData.imageUrl && !uploading && (
                            <Button
                                variant="text"
                                component="span"
                                sx={{
                                    backgroundColor: 'white',
                                    color: 'rgba(57, 57, 67, 1)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(245, 245, 245, 1)',
                                    },
                                }}
                            >
                                Add Image
                            </Button>
                        )}
                    </label>
                    <Input
                        id="upload-image"
                        type="file"
                        inputProps={{ accept: 'image/*' }}
                        sx={{ display: 'none' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const file = e.target.files?.[0];
                            if (file) handleImageUpload(file);
                        }}
                        onBlur={() => handleBlur('imageUrl')}
                    />
                    {(formData.imageUrl || uploading) && (
                        <Stack sx={{ width: 1 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    gap: '12px',
                                }}
                            >
                                <Typography mb={0} fontSize={12}>
                                    {formData.imageFile?.name}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography mb={0} fontSize={10} mt={'3px'}>
                                        {formData.imageFile
                                            ? (formData.imageFile.size / (1024 * 1024)).toFixed(2) +
                                            ' MB'
                                            : ''}
                                    </Typography>
                                    {
                                        <IconButton
                                            size={'small'}
                                            sx={{ mt: '3px', ml: '12px' }}
                                            disabled={uploading}
                                            onClick={() =>
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    imageFile: null,
                                                    imageUrl: null,
                                                }))
                                            }
                                        >
                                            <Close sx={{ fontSize: '16px' }} />
                                        </IconButton>
                                    }
                                </Box>
                            </Box>
                            {uploading && <LinearProgress />}
                        </Stack>
                    )}
                </Box>
                {uploadError && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        {uploadError}
                    </Typography>
                )}

                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 5, height: '48px', borderRadius: '12px' }}
                    onClick={handleSubmit}
                    disabled={!user || !isFormValid || loading}
                    startIcon={<AddCircleOutlineRoundedIcon />}
                >
                    {loading ? <CircularProgress size={24} /> : 'Issue Tokens'}
                </Button>
            </Box>
            <SnackbarComponent />
        </BasePageLayout>
    );
};

export default IssueTokensPage;