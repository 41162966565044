import BasePageLayout from '../../components/BasePageLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';

const AllRight = () => {
    const navigate = useNavigate();

    return (
        <BasePageLayout showBack={false}>
            <Stack justifyContent='space-around' alignItems='center' height={'calc(90dvh - 90px - 90px)'}>
                <Stack alignItems={'center'}>
                    <Box height={72} width={72} bgcolor={'rgba(255, 166, 41, 0.1)'} display={'flex'} borderRadius='50%' alignItems='center' justifyContent='center'>
                        <Box height={33} width={33} bgcolor={'rgba(255, 166, 41, 1)'} display={'flex'} borderRadius='50%' alignItems='center' justifyContent='center'>
                            <Check fontSize={'small'} sx={{color: 'white'}}/>
                        </Box>
                    </Box>
                    <Typography color={'rgba(18, 18, 18, 1)'} fontSize={20} fontWeight={500}>All right!</Typography>
                    <Typography color={'rgba(71, 71, 71, 1)'} fontSize={14}>Tossed!</Typography>
                </Stack>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => navigate('/home')}
                >
                    Home
                </Button>
            </Stack>
        </BasePageLayout>
    );
};

export default AllRight;