import { createTheme } from '@mui/material';

export const theme = createTheme({
    typography: {
        fontFamily: 'Plus Jakarta Sans',
        h1: {
            fontSize: '42px',
            fontWeight: 700,
            color: 'rgba(57, 57, 67, 1)',
            marginBottom: '30px',
        },
        body1: {
            fontSize: '14px',
            color: 'rgba(44, 44, 44, 1)',
            marginBottom: '8px',
        }
    },
    palette: {
        error: {
            main: 'rgba(245, 65, 53, 1)',
        },
        warning: {
            main: 'rgba(255, 166, 41, 1)',
            contrastText: 'white'
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        borderRadius: '8px',
                        marginBottom: 0,
                        height: '48px',
                        color: 'rgba(44, 44, 44, 1)',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '14px',
                    height: '48px',
                    borderRadius: '8px',
                    fontSize: '16px',
                    fontWeight: 500,
                    backgroundColor: 'rgba(57, 57, 67, 1)',
                    textTransform: 'none',
                    boxShadow: 'none !important',
                    '&:hover': {
                        backgroundColor: 'rgba(46, 46, 54, 1)',
                    }
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    color: 'rgba(66, 128, 239, 1)',
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: 'rgba(224, 224, 224, 1)',
                    '&.Mui-checked': {
                        color: 'black',
                    }
                },
            },
        },
    },
});